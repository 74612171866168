<template>
  <v-dialog
    v-model="changeStatus.dialog"
    max-width="400px"
    @click:outside="resetChangeStatus()"
  >
    <v-card color="#FBF8E9">
      <v-card-title class="headline">Change Status</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <div class="mb-5">Are you sure you want to Verify documents?</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="secondary" text @click="resetChangeStatus()"
          >No, Cancel</v-btn
        >
        <v-btn
          color="accent"
          :loading="changeStatus.loading"
          @click="changeStatusConfirmed()"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      changeStatus: {
        form_status: null,
        errors: {},
        dialog: false,
        loading: false,
        comment_text: null,
        field: null,
        comments: {},
      },
    };
  },

  methods: {
    formatDate(dateString) {
      return moment(dateString).format("DD/MM/YYYY HH:mm");
    },
    formComment(field) {
      const appId = this.$route.params.id;
      const formId = this.$route.params.formId;

      this.$store
        .dispatch("easylets/applicationformsStore/uploadStatus", {
          appId,
          formId,
          field: field,
        })
        .then((res) => {
          this.changeStatus.dialog = true;
          this.changeStatus.comments = res.data.comments;
        })
        .catch(() => {
          this.resetChangeStatus();
        });
    },
    changeStatusPrompt: function (form_status, field) {
      this.changeStatus.form_status = form_status;
      this.changeStatus.field = field;
      this.formComment(field);
    },
    resetChangeStatus: function () {
      this.changeStatus.form_status = null;
      this.changeStatus.comment_text = null;
      this.changeStatus.field = null;
      this.changeStatus.dialog = false;
      this.changeStatus.loading = false;
      this.changeStatus.comments = {};
    },
    changeStatusConfirmed: function () {
      const appId = this.$route.params.id;
      const formId = this.$route.params.formId;

      this.changeStatus.loading = true;

      this.$store
        .dispatch("easylets/applicationformsStore/changeStatusUpload", {
          appId,
          formId,
          form_status: this.changeStatus.form_status,
          comment_text: this.changeStatus.comment_text,
          field: this.changeStatus.field,
        })
        .then(() => {
          this.resetChangeStatus();
        })
        .catch(() => {
          this.resetChangeStatus();
        });
    },
  },
};
</script>
