<template>
  <v-dialog
    v-model="changeStatus.dialog"
    max-width="400px"
    @click:outside="resetChangeStatus()"
  >
    <v-card color="#FBF8E9">
      <v-card-title class="headline">Comments:</v-card-title>
      <v-divider></v-divider>
      <v-card-subtitle
        >This comment will be visible to all applicants who form part of this
        application.<br />Note for staff: include section tenant is expected to
        update & which tenant the note applies to</v-card-subtitle
      >

      <v-card-text class="pt-5">
        <v-textarea
          label="Add a comment"
          type="textarea"
          rows="3"
          auto-grow
          v-model="changeStatus.comment_text"
          outlined
          background-color="white"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="white--text"
          color="#C77F42"
          @click="changeStatusConfirmed()"
          :loading="changeStatus.loading"
          >Add</v-btn
        >
        <v-btn class="white--text" color="#91BAA1" @click="resetChangeStatus()"
          >Cancel</v-btn
        >
      </v-card-actions>
      <v-card-text
        class="pt-5"
        color="#F2E6CF"
        v-if="changeStatus.comments.length > 0"
      >
        <div
          class="mt-2"
          v-for="comment in changeStatus.comments"
          v-bind:key="comment.form_comments_id"
        >
          <h3>
            {{ formatDate(comment.comment_date_time) }}
            {{ comment.user.full_name }}
          </h3>
          {{ comment.comment_text }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      changeStatus: {
        form_status: null,
        errors: {},
        dialog: false,
        loading: false,
        comment_text: null,
        field: null,
        comments: {},
      },
    };
  },

  methods: {
    formatDate(dateString) {
      return moment(dateString).format("DD/MM/YYYY HH:mm");
    },
    formComment(field) {
      const appId = this.$route.params.id;
      const formId = this.$route.params.formId;

      this.$store
        .dispatch("easylets/applicationformsStore/uploadStatus", {
          appId,
          formId,
          field: field,
        })
        .then((res) => {
          this.changeStatus.dialog = true;
          this.changeStatus.comments = res.data.comments;
        })
        .catch(() => {
          this.resetChangeStatus();
        });
    },
    changeStatusPrompt: function (form_status, field) {
      this.changeStatus.form_status = form_status;
      this.changeStatus.field = field;
      this.formComment(field);
    },
    resetChangeStatus: function () {
      this.changeStatus.form_status = null;
      this.changeStatus.comment_text = null;
      this.changeStatus.field = null;
      this.changeStatus.dialog = false;
      this.changeStatus.loading = false;
      this.changeStatus.comments = {};
    },
    changeStatusConfirmed: function () {
      const appId = this.$route.params.id;
      const formId = this.$route.params.formId;

      this.changeStatus.loading = true;

      this.$store
        .dispatch("easylets/applicationformsStore/changeStatusUpload", {
          appId,
          formId,
          form_status: this.changeStatus.form_status,
          comment_text: this.changeStatus.comment_text,
          field: this.changeStatus.field,
        })
        .then(() => {
          this.resetChangeStatus();
        })
        .catch(() => {
          this.resetChangeStatus();
        });
    },
  },
};
</script>
